import React, { useEffect, useState } from "react";
import { LayoutSplashScreen } from "../_metronic/layout";
import { EncryptStorage } from "encrypt-storage";
import { GetCurrentLawfirm, GetUserRole } from "../Functions/General";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import { setsubscriptionData } from "../redux/slices/subscriptionDataSlice";

export default function DataStore({children}){
    const userRole                      = GetUserRole();
    const lawfirm                       = GetCurrentLawfirm();
    const encryptStorage                = new EncryptStorage("secret-key");
    const dispatch                      = useDispatch();
    const [loading, setLoading]         = useState(true);
    const { user }                      = useSelector(state => state.auth);
    
    const handleFetchImportantData = async () => {
        if (userRole === 'client') {
            const caseId = user.data.cases[0].id;
            let caseDetails = {};
            let spouseCaseDetails = {};
          
            // This will make sure to clean the case_details in local storage before setting a new one
            encryptStorage.removeItem('case_details');
            try {
              const response = await Axios.get(`/api/case/${caseId}`);
          
              // Client-side cleaning unnecessary data
              caseDetails = response.data;
              caseDetails.case.lawfirm.invitations = 'empty';
              caseDetails.case.lawfirm.cases = 'empty';
          
              spouseCaseDetails = {
                ...response.data.case.spouse,
                client_case: undefined,
              };
          
              encryptStorage.setItem('case_details', JSON.stringify(caseDetails));
              encryptStorage.setItem('spouse', JSON.stringify(spouseCaseDetails));
            } catch (error) {
              if (error.response && error.response.status === 401) {
                console.log("The error is 401 in the first get request");
                localStorage.removeItem("case_details");
                window.location.href = "/logout";
                }else{
                console.log("The error is not 401 it is:",error);
                }
            }
          
            await Axios.get(`/api/v2/client_modules/${caseId}`).then((res) => {
              encryptStorage.setItem('client_modules', res.data.data);
            });
          
            try {
                const subscriptionResponse = await Axios.get(
                  `/api/subscription/retrieve/user/${lawfirm.owner[0].id}`
                );
                let subs_data = subscriptionResponse.data.subscription_data;
                dispatch(setsubscriptionData(subs_data));
              } catch (error) {
            if (error.response && error.response.status === 401) {
                console.log("The error is 401 in the first get request");
                localStorage.removeItem("case_details");
                window.location.href = "/logout";
            }else{
                console.log("The error is not 401 it is:",error);
            }

              } finally {
                setLoading(false);
              }
        }
        
        /**
         * OTHER ROLES IMPORTANT DATA
         * TODO: REFACTOR DATA STORAGE.
         */
        
        //TODO: ENCAPSULATION
        try {
              const subscriptionResponse = await Axios.get(
                `/api/subscription/retrieve/user/${lawfirm.owner[0].id}`
              );
              let subs_data = subscriptionResponse.data.subscription_data;
              dispatch(setsubscriptionData(subs_data));
          } catch (error) {
              if (error.response && error.response.status === 401) {
                  console.log("The error is 401 in the first get request");
                  localStorage.removeItem("case_details");
                  window.location.href = "/logout";
              }else{
                  console.log("The error is not 401 it is:",error);
              }
            }
        setLoading(false);
    };
    
    useEffect(() => {
        handleFetchImportantData();
        return(() => {});
    },[]);

    return loading ? <LayoutSplashScreen /> : <>{children}</>;
};