import React, { useEffect, useState } from "react";
import ActiveClients from "./AttorneyDashboardComponents/AttoryneyDashboard.ActiveClients";
import ProspectiveClients from "./AttorneyDashboardComponents/AttorneyDashboard.ProspectiveClient";
import RecentDocuments from "./AttorneyDashboardComponents/AttorneyDashboard.RecentDocuments";
import RecentActivities from "./AttorneyDashboardComponents/AttorneyDashboard.RecentAcitivities";
import Todo from "./AttorneyDashboardComponents/AttorneyDashboard.Todo";
import VideoTutorials from "./AttorneyDashboardComponents/AttorneyDashboard.VideoTutorials";
import DataUsageComponent from "./AttorneyDashboardComponents/AttorneyDashboard.DataUsage";
import Axios from "axios";
import { GetCurrentLawfirmOwner } from "../../Functions/General";
import { Alert, AlertTitle } from "@material-ui/lab";
import { EncryptStorage } from "encrypt-storage";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom/cjs/react-router-dom";

function SubscriptionAlert({status}){
    return (
        <Alert severity={`${status === 'expired' ? 'error' : status === 'warning' ? 'warning' : 'success'}`} hidden={status !== 'expired' && status !== 'warning'}>
            <AlertTitle>
                {status === 'expired' && `Subscription Expired`}
                {status === 'warning' && `Warning`}
            </AlertTitle>
            {status === 'expired' && (
                <>
                Oops! It seems like your subscription has expired. Kindly
                <NavLink to="/subscription">
                    subscribe
                </NavLink>
                to continue enjoying our services.
                </>
            )}
            {status === 'warning' && `Your subscription is about to expire. Consider renewing to avoid any interruptions in service.`}
        </Alert>
    )
};

export default function AttorneyDashboard(){
    const state = useSelector(state => state);
    const { membership } = useSelector(state => state.lawfirm);
    return(
        <div className="row">
            <div className="col-12">
                <SubscriptionAlert status={membership}/>
            </div>
            <div className=" py-4 col-md-6 col-sm-12 col-xxl-4">
                <ActiveClients membership={membership} />
            </div>
            <div className=" py-4 col-md-6 col-sm-12 col-xxl-4">
                <ProspectiveClients membership={membership} />
            </div>
            <div className=" py-4 col-md-6 col-sm-12 col-xxl-4">
                <RecentDocuments />
            </div>
            <div className=" py-4 col-md-6 col-sm-12 col-xxl-4">
                <RecentActivities />
            </div>
            {/* <Todo /> */}
            <div className=" py-4 col-md-6 col-sm-12 col-xxl-4">
                <DataUsageComponent />
            </div>
            <VideoTutorials />
        </div>
    );
}