import Axios from "axios";
import { EncryptStorage } from "encrypt-storage";
import React, { Suspense, lazy, useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import { PageIsSubscribed } from "../Functions/General";
import NoSubscriptionClient from "./modules/UserProfile/Subscription/Client/NoSubscriptionClient";
import Basics from "./pages/Basics/Basics";
import ClientOverview from "./pages/Basics/ClientOverview";
import Budget from "./pages/Budget/Budget";
import Children from "./pages/Children/Children";
import Documents from "./pages/Documents/DocumentPage";
import Estate from "./pages/Estate/Estate";
import EstateDefault from "./pages/Estate/EstateDefault";
import EstateDivision from "./pages/Estate/EstateDivision";
import IdleComponent from "./pages/IdleComponent";
import NotesPage from "./pages/Notes/NotesPage";
import Timeline from "./pages/Timeline/Timeline";

const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function ClientPage() {
  const encryptStorage = new EncryptStorage("secret-key");
  const decypt_data = encryptStorage.getItem("case_details");
  const { user } = useSelector((state) => state.auth);
  const role = user ? user.data.roles[0].name : null;
 
  const handleFetchChat = async () => {
    try {
      await Axios.get(`${process.env.REACT_APP_CHAT_API}/live-chat/?userId=${user.data.id}`)
      .then((res) => {
        const script = document.createElement('script');
        script.innerHTML = res.data;
        document.head.appendChild(script);
      });
    } catch (error) {
      console.error(error);
    }
  };

  window.addEventListener('message', (event) => {
    if (event.data.action === 'updateParentContainer') {
        const iframeContainer = document.querySelector('.clientwiseportal-chat-widget iframe');
        iframeContainer.style.height = event.data.value;
        console.log('VALUE: ', event.data.value);
    }
  });
  
  useEffect(() => {
    encryptStorage.removeItem("client");
    encryptStorage.removeItem("client_case_id");
    encryptStorage.setItem("client", decypt_data.case.client);
    encryptStorage.setItem("client_case_id", decypt_data.case?.id);
    handleFetchChat();
  }, []);
  
  const getSizeOfLocalStorage = () => {
    const data = JSON.stringify(localStorage);
    const sizeInBytes = new Blob([data]).size;
    return sizeInBytes;
  };
  
  const localStorageSize = getSizeOfLocalStorage();

  return (
    <>
      <IdleComponent />
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {PageIsSubscribed() ? (<Redirect from="/" to="/client-overview" exact/>) : (<ContentRoute path="/" component={NoSubscriptionClient} />)}
          <ContentRoute
            exact
            path="/"
            component={
              PageIsSubscribed()
                ? () => <ContentRoute path="/client-overview" component={ClientOverview} />
                : () => (
                    <ContentRoute path="/" component={NoSubscriptionClient} />
                  )
            }
          />
          
          {PageIsSubscribed() ? (
            <ContentRoute path="/client-overview" component={ClientOverview} />
          ) : (
            <ContentRoute path="/" component={NoSubscriptionClient} />
          )}

          {PageIsSubscribed() ? (
            <ContentRoute path="/timeline" component={Timeline} />
          ) : (
            <ContentRoute path="/" component={NoSubscriptionClient} />
          )}
          {PageIsSubscribed() ? (
            <ContentRoute path="/estates/default" component={EstateDefault} />
          ) : (
            <ContentRoute path="/" component={NoSubscriptionClient} />
          )}
          {PageIsSubscribed() ? (
            <ContentRoute path="/documents" component={Documents} />
          ) : (
            <ContentRoute path="/" component={NoSubscriptionClient} />
          )}

          {PageIsSubscribed() ? (
            <ContentRoute path="/notes" component={NotesPage} />
          ) : (
            <ContentRoute path="/" component={NoSubscriptionClient} />
          )}
          {PageIsSubscribed() ? (
            <ContentRoute 
            path="/basics" 
            render={() => <Basics mode={'client'} />}
            />
          ) : (
            <ContentRoute path="/" component={NoSubscriptionClient} />
          )}
          {PageIsSubscribed() ? (
            <ContentRoute path="/budget/" component={Budget} />
          ) : (
            <ContentRoute path="/" component={NoSubscriptionClient} />
          )}
          {PageIsSubscribed() ? (
            <Route path="/user-profile" component={UserProfilepage} />
          ) : (
            <ContentRoute path="/" component={NoSubscriptionClient} />
          )}
          <ContentRoute path="/estate" component={Estate} />
          <ContentRoute path="/estate-division" component={EstateDivision} />
          <ContentRoute path="/children" component={Children} />
          <Redirect exact from="/auth/mfa" to={role === "client" ? "/" : "/attorney"} />  {/* remove this if things go wrong */}
          <Redirect exact from="/auth/login" to={role === "client" ? "/" : "/attorney"} />      {/* remove this if things go wrong */}
          <Redirect to="../error/error-v3" />
        </Switch>
      </Suspense>
    </>
  );
}
